import {load_template} from "./templating";
import {GetCallerIdentityCommand, STSClient} from "@aws-sdk/client-sts"
const roleOrder = ['Ops', 'ReadOnly', 'SecurityAudit', 'LogsAndMetrics']
// keeps the last used roles for re-using
const role_cache = {}
const accounts_cache = {}
const accounts_roles_cache = {}
let principal = undefined

/**
 * Ensures we have an access token. TODO: Implement a grace period that the token must be valid at least.
 * @returns {Promise<void>}
 */
export async function ensure_credentials() {
    console.log("Waiting for access token if needed...")
    await get_access_token_or_login(false)
    console.log("Found valid access token.")
}

export async function get_role_credentials(accountId, force_login = false, roleName = null) {
    const accessTokenObject = await get_access_token_or_login(force_login)
    if (accessTokenObject == null || accessTokenObject.accessToken == null) {
        console.error("No access token available!")
        return null
    }
    if(roleName == null) {
        roleName = await get_preferred_role_for_account(accountId)
    }
    if (roleName == null) {
        return null
    }

    // check cache
    const cached_role = role_cache[`${accountId}:${roleName}`]
    if (cached_role !== undefined && cached_role.expiration / 1000 > now_seconds() + 5 * 60) { // exists and not expired
        console.log(`Using cached temporary credentials for accountId=${accountId}, roleName=${roleName}`);
        return cached_role
    }

    let login_response = await fetch("https://dtn4isml2f6jdae674x27jce3u0vuufh.lambda-url.eu-west-1.on.aws/", {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            action: 'get_role_credentials',
            accountId: accountId,
            roleName: roleName,
            accessToken: accessTokenObject.accessToken
        })
    });

    const login_body = await login_response.json();
    if (login_body.Error !== undefined) {
        if (login_body.Error.Code === "UnauthorizedException" && force_login === false) { // prevent endless loop
            console.info(`Current session expired: ${login_body.Error.Code} ${login_body.Error.Message}`)
            return await get_role_credentials(accountId, true)
        }
        console.error(`Unable to get role credentials for accountId=${accountId}, roleName=${roleName}: ${login_body.Error.Code} ${login_body.Error.Message}`)
    } else {
        login_body.roleCredentials['roleName'] = roleName; // save roleName with credentials
        role_cache[`${accountId}:${roleName}`] = login_body.roleCredentials
        console.log(`Fetched temporary credentials for accountId=${accountId}, roleName=${roleName}`);

        // display username on first role assumption
        if (principal === undefined) {
            //TODO: IAM regions are global, but shall we default to eu-west-1?
            const client = new STSClient({region: "eu-west-1", credentials: login_body.roleCredentials});
            const response = await client.send(new GetCallerIdentityCommand({}));
            principal = response.UserId.split(":")[1]
            document.getElementById("username").innerText = principal
            console.log(response)
        }
    }
    // can return undefined in error cases
    return login_body.roleCredentials;
}

async function get_preferred_role_for_account(accountId) {
    const accounts_and_roles = await get_accounts_and_roles()
    const account = accounts_and_roles[accountId]
    if (account !== undefined) {
        for (const role of roleOrder) {
            if (account.roles.includes(role)) {
                return role
            }
        }
    }
    throw Error("You don't have access to account id: " + accountId + " or the account id doesn't exist.")
}

export function sleep(ms) {
    return new Promise(r => setTimeout(r, ms));
}

function now_seconds() {
    return Math.floor(Date.now() / 1000)
}

async function get_access_token_or_login(force_login = false) {
    let accessTokenObject = null;
    try {
        accessTokenObject = JSON.parse(localStorage.getItem('AwsSsoAccessToken'));
    } catch (e) {
        console.warn("Unable to parse local storage key 'AwsSsoAccessToken'", e)
    }

    // show login dialog if needed
    const expireDeadline = Date.now() / 1000 + (5 * 60) // refresh if it would expire in the next 5 minutes
    if (accessTokenObject == null || accessTokenObject.accessToken == null || accessTokenObject.expiresAt < expireDeadline || force_login) {
        console.info("No accessToken available, showing login dialog....")
        accessTokenObject = await show_login_dialog_blocking();
    }
    return accessTokenObject
}

async function show_login_dialog_blocking() {
    load_template('box_login', 'modal')
    const modal = document.getElementById('modal')
    modal.style.display = "block";
    return new Promise((resolve) => {
        document.getElementById('login').onclick = async () => {
            const login_response = await aws_login();
            resolve(login_response);
            modal.style.display = "none";
        };
    });
}

async function aws_login() {
    let login_response = await fetch("https://dtn4isml2f6jdae674x27jce3u0vuufh.lambda-url.eu-west-1.on.aws/", {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({action: 'start_device_authorization'})
    });

    let login_body = await login_response.json();

    console.log("Request complete! response:", login_body);
    let login_tab = window.open(login_body.verificationUriComplete);

    // we now need to poll until the user finished the authorization in the new tab
    let expiresAt = now_seconds() + login_body.expiresIn;

    let poll_body = {};
    while (now_seconds() <= expiresAt && poll_body.accessToken === undefined) {
        await sleep(1000)
        let poll_response = await fetch("https://dtn4isml2f6jdae674x27jce3u0vuufh.lambda-url.eu-west-1.on.aws/", {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({action: 'create_token', 'deviceCode': login_body.deviceCode})
        });
        poll_body = await poll_response.json()
        console.info(poll_body);
    }

    login_tab.close();

    // check success
    if (poll_body.accessToken !== undefined) {
        // login successful
        poll_body.expiresAt = now_seconds() + poll_body.expiresIn // set expire date
        localStorage.setItem('AwsSsoAccessToken', JSON.stringify(poll_body))
    } else {
        // login failed, clear storage just in case
        localStorage.removeItem('AwsSsoAccessToken')
        console.error(poll_body)
    }
    return poll_body
}

export async function aws_logout() {
    localStorage.removeItem('AwsSsoAccessToken')
    //TODO: call sso logout
    load_template('logout', 'content_holder')
}

async function refresh_accounts_and_roles() {
    const accessTokenObject = await get_access_token_or_login()
    console.info("Refreshing list of accounts and roles...")
    let accountsResponse = await fetch("https://dtn4isml2f6jdae674x27jce3u0vuufh.lambda-url.eu-west-1.on.aws/", {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({action: 'list_accounts', accessToken: accessTokenObject.accessToken})
    });
    return accountsResponse.json().then((response) => {
        if (response.Error !== undefined) {
            throw Error(response.Error.Code + ": " + response.Error.Message)
        }
        response._last_update = Date.now()
        localStorage.setItem('AwsAccountsAndRoles', JSON.stringify(response))
        console.info("Read accounts and roles:", response)
        return response
    })
}

async function get_accounts_and_roles(onlyWithRoleAccess = false) {
    let accounts_and_roles = null
    try {
        accounts_and_roles = JSON.parse(localStorage.getItem('AwsAccountsAndRoles'))
    } catch (e) {
        console.warn("Unable to read local storage 'AwsAccountsAndRoles'. Refreshing list.", e)
    }
    if (accounts_and_roles == null || accounts_and_roles['_last_update'] === undefined || accounts_and_roles['_last_update'] < (Date.now() - 60 * 60 * 1000)) {
        accounts_and_roles = await refresh_accounts_and_roles()
    }
    return accounts_and_roles
}

export async function listAccounts(onlyWithRoleAccess = false) {
    const accounts = await get_accounts_and_roles(onlyWithRoleAccess)
    if(onlyWithRoleAccess) {
        const accountIds = []
        for (const [accountId, value] of Object.entries(accounts)) {
            if(accountId.startsWith('_')) { // skip metadata
                continue
            }
            if(value.roles.length > 0) {
                accountIds.push(accountId)
            }
        }
        return accountIds
    } else {
        return Object.keys(accounts)
    }
}

/**
 * Lists all known account ids. For some accounts there might not be a suitable role.
 * Account Ids are cached in the window (invalidated on refresh).
 * @param filter account_id or null if all accounts should be returned
 * @returns {Promise<string[]|*[]>}
 */
export async function listAccountsFiltered(filter = null) {
    let accounts = accounts_cache
    if(Object.keys(accounts).length === 0) {
        // empty cache, fetch accounts from API
        const accessTokenObject = await get_access_token_or_login()
        let accountsResponse = await fetch("https://dtn4isml2f6jdae674x27jce3u0vuufh.lambda-url.eu-west-1.on.aws/", {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({action: 'list_account_ids', accessToken: accessTokenObject.accessToken})
        });
        accounts = await accountsResponse.json()
        for (const k in accounts) accounts_cache[k] = accounts[k] // copy to cache
    }
    if(filter === null) {
        return accounts
    } else if(accounts[filter] != null) {
        const result = {}
        result[filter] = accounts[filter]
        return result
    } else {
        throw Error("Unable to find account_id = " + filter)
    }
}

/**
 * @returns {Promise<string[]|*[]>} a credentials object for the given account_id or null of no credentials are available for that account
 */
export async function getPreferredCredentials(account_id) {
    const roleName = await getPreferredRoleName(account_id)
    if(roleName != null) {
        return await get_role_credentials(account_id, false, roleName)
    } else {
        return null
    }
}

/**
 * @returns {Promise<string>} the preferred role name for the given account_id
 */
export async function getPreferredRoleName(account_id) {
    if(accounts_roles_cache[account_id] == null) {
        const accessTokenObject = await get_access_token_or_login()
        let accountRolesResponse = await fetch("https://dtn4isml2f6jdae674x27jce3u0vuufh.lambda-url.eu-west-1.on.aws/", {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({action: 'list_account_roles', accountId:account_id, accessToken: accessTokenObject.accessToken})
        });
        const account_roles = await accountRolesResponse.json()
        accounts_roles_cache[account_id] = []
        for(const entry of account_roles) accounts_roles_cache[account_id].push(entry.roleName) // copy to cache
    }
    for (const role of roleOrder) {
        if (accounts_roles_cache[account_id].includes(role)) {
            return role
        }
    }
    return null
}

export async function getAccountName(accountId) {
    const accounts_and_roles = await get_accounts_and_roles()
    const account = accounts_and_roles[accountId]
    if(account === undefined) {
        return "*unknown*"
    } else {
        return account.accountName
    }
}

export async function open_url(page) {
    const targetUrl = page.parameters.url
    const accountId = page.parameters.accountId
    const roleName = page.parameters.role || await getPreferredRoleName(accountId)
    const url = await getAwsConsoleUrl(accountId, targetUrl, roleName)
    await load_template('open_url', 'content_holder', {
        accountId: accountId,
        accountName: await getAccountName(accountId),
        roleName: roleName,
        url: url,
        niceUrl: targetUrl
    })

    // Automatically forward to url if the referrer has the same origin
    if (document.referrer !== null && document.referrer.indexOf(location.protocol + "//" + location.host) === 0) {
        window.location.href = url
    }
}

export async function getAwsConsoleUrl(accountId, targetUrl, roleName) {
    const resolvedRoleName = roleName || await getPreferredRoleName(accountId)
    return `https://d-93672e9846.awsapps.com/start/#/console?account_id=${accountId}&role_name=${resolvedRoleName}&destination=${encodeURIComponent(targetUrl)}`
}


window.open_aws_console = async function (accountId, consoleUrl) {
    const url = await get_federated_session_url(accountId, consoleUrl)
    window.open(url)
}
window.aws_login = aws_login;