import {getPreferredCredentials, listAccountsFiltered,} from "./authentication";
import {load_template} from "./templating";
import {DescribeImageScanFindingsCommand, ECRClient} from "@aws-sdk/client-ecr";
import {DescribeTaskDefinitionCommand, ECSClient, ListTaskDefinitionsCommand} from "@aws-sdk/client-ecs";
import {Inspector2Client, ListFindingAggregationsCommand, ListFindingsCommand} from "@aws-sdk/client-inspector2";

export async function show_inspector_view(page) {

    if (page.path === "/inspector/") {
        await show_inspector(page)
    } else if (page.path === "/inspector/ecr/image/") {
        await show_image(page)
    } else {
        throw Error("not found")
    }

}

async function fetchActiveTaskDefinitionImageTags(ecsClient) {
    let nextToken = undefined
    let lastTaskDefinitionName = null
    let taskDefinitionCounter = 0
    const images = new Map()
    const skippedTaskDefinitionArns = new Set()
    do {
        const responseTaskDefinitions = await ecsClient.send(new ListTaskDefinitionsCommand({
            nextToken: nextToken,
            sort: "DESC"
        }))
        for (const tdArn of responseTaskDefinitions.taskDefinitionArns) {
            const result = /(arn:aws:ecs:[\w\-]+:\d+:task-definition\/[\w\-]+):(\d+)/.exec(tdArn)
            const arn = result[1]
            //we only want to load the 10 latest revisions
            if (arn === lastTaskDefinitionName) {
                taskDefinitionCounter++
            } else {
                lastTaskDefinitionName = arn
                taskDefinitionCounter = 0
            }

            if (taskDefinitionCounter <= 10) {
                const response = await ecsClient.send(new DescribeTaskDefinitionCommand({taskDefinition: tdArn}))
                for (const container of response.taskDefinition.containerDefinitions) {
                    images.set(container.image, arn)
                }
            } else {
                skippedTaskDefinitionArns.add(arn)
            }
        }
        nextToken = responseTaskDefinitions.nextToken
    }
    while (nextToken !== undefined)
    return {images: images, skippedArns: skippedTaskDefinitionArns}
}

async function show_inspector(page) {
    const root_template = load_template('inspector', 'content_holder')

    const accounts = await listAccountsFiltered(page.parameters.accountId)
    const regions = page.parameters.region !== undefined ? [page.parameters.region] : ["eu-west-1", "us-east-1", "eu-central-1"]

    let showOnlyActiveTaskDefinitions = false
    if (page.parameters.repository === undefined && page.parameters.only_active !== 'false') {
        page.notifications_template.append("info", {message: "Showing only images in active taskDefinitions (in the same account and region)"})
        showOnlyActiveTaskDefinitions = true
    }

    const progressBar = document.getElementById("progress")
    progressBar.style.display = "block"
    progressBar.min = 0
    progressBar.max = Object.keys(accounts).length * regions.length
    const now = new Date()
    const promises = []
    for (const [accountId, accountDetails] of Object.entries(accounts)) {
        const await_handle = getPreferredCredentials(accountId) // fetches all accounts in parallel
            .then(async function (credentials) {
                    if (credentials) {
                        for (const region of regions) {
                            // ECR
                            const ecr_client = new ECRClient({region: region, credentials: credentials})
                            let allImageTags = undefined

                            const result = await fetchActiveTaskDefinitionImageTags(new ECSClient({
                                region: region,
                                credentials: credentials
                            }))
                            allImageTags = result.images
                            console.log(`found ${allImageTags.size} ACTIVE container images in task definitions for account ${accountId} and region ${region}.`)
                            if (result.skippedArns.size > 0) {
                                page.notifications_template.append("info", {message: `${accountDetails['accountName']} ${region}: Limited to latest 10 TaskDefinitions for: ${[...result.skippedArns].join(' ')}`})
                            }

                            const inspectorClient = new Inspector2Client({region: region, credentials: credentials})

                            let aggregationRequest = undefined
                            if (page.parameters.repository !== undefined) {
                                aggregationRequest = {
                                    awsEcrContainerAggregation: {
                                        repositories: [{
                                            comparison: "EQUALS",
                                            value: page.parameters.repository,
                                        }]
                                    }
                                }
                            }
                            let nextToken = undefined
                            do {
                                const response = await inspectorClient.send(new ListFindingAggregationsCommand({
                                    aggregationType: "AWS_ECR_CONTAINER",
                                    accountIds: [
                                        {
                                            comparison: "EQUALS",
                                            value: accountId, // relevant for "administrator"  because it aggregates all images
                                        },
                                    ],
                                    aggregationRequest: aggregationRequest,
                                    nextToken: nextToken
                                }))
                                for (const aggregations of response.responses) {
                                    const awsEcrContainerAggregation = aggregations.awsEcrContainerAggregation
                                    //skip image if not in active taskdefinition
                                    const taskDefinitions = []
                                    for (const tag of awsEcrContainerAggregation.imageTags || []) {
                                        const td = allImageTags.get(`${awsEcrContainerAggregation.accountId}.dkr.ecr.${region}.amazonaws.com/${awsEcrContainerAggregation.repository}:${tag}`)
                                        if (td !== undefined) {
                                            taskDefinitions.push(td)
                                        }
                                    }
                                    if (showOnlyActiveTaskDefinitions && taskDefinitions.length === 0) {
                                        continue // skip row if filter is active
                                    }
                                    root_template.append('ecr_image_vulnerabilities', {
                                        accountId: awsEcrContainerAggregation.accountId,
                                        accountName: accounts[awsEcrContainerAggregation.accountId].accountName,
                                        region: region,
                                        repository: awsEcrContainerAggregation.repository,
                                        image: {
                                            digest: awsEcrContainerAggregation.imageSha,
                                            tags: awsEcrContainerAggregation.imageTags,
                                            severityCounts: awsEcrContainerAggregation.severityCounts
                                        },
                                        taskDefinitions: taskDefinitions
                                    })
                                }
                                nextToken = response.nextToken
                            } while (nextToken !== undefined)
                            progressBar.value += 1
                        }
                    }
                }
            ).catch((e) => {
                console.warn("Unable to fetch repositories for account " + accountId + ": ", e)
            })
        promises.push(await_handle)
    }
    await Promise.all(promises)
    progressBar.style.display = "none"
}

function severityOrder(severity) {
    switch (severity) {
        case "CRITICAL":
            return 0
        case "HIGH":
            return 1
        case "MEDIUM":
            return 2
        case "LOW":
            return 3
        case "INFORMATIONAL":
            return 4
        case "UNTRIAGED":
            return 5
        default:
            return 9
    }
}

async function show_image(page) {
    const root_template = load_template('inspector_image', 'content_holder')
    const accounts = await listAccountsFiltered(page.parameters.accountId)
    const region = page.parameters.region
    const credentials = await getPreferredCredentials(page.parameters.accountId)
    const client = new ECRClient({region: region, credentials: credentials})
    // const imagePromise = client.send(new BatchGetImageCommand({
    //     repositoryName: page.parameters.repository,
    //     imageIds: [{imageDigest: page.parameters.digest}]
    // }))
    const findingsPromise = client.send(new DescribeImageScanFindingsCommand({
        repositoryName: page.parameters.repository,
        imageId: {
            imageDigest: page.parameters.digest
        }
    }))

    // Initialize the Inspector2 client
    const clientInspector = new Inspector2Client({region: region, credentials: credentials}); // Replace with your region

    const inspectorFindingAggregationPromise = clientInspector.send(new ListFindingAggregationsCommand({
        aggregationType: "AWS_ECR_CONTAINER",
        aggregationRequest: {
            awsEcrContainerAggregation: {
                repositories: [{comparison: "EQUALS", value: page.parameters.repository}],
                imageShas: [{comparison: "EQUALS", value: page.parameters.digest}]
            }
        }
    }));

    let nextToken = undefined
    let inspectorFindings = []
    do {
        const r = await clientInspector.send(new ListFindingsCommand({
            filterCriteria: {
                ecrImageHash: [{comparison: "EQUALS", value: page.parameters.digest}],
                findingStatus: [
                    {comparison: "EQUALS", value: "ACTIVE"}
                ],
            },
            sortCriteria: {
                field: "SEVERITY",
                sortOrder: "DESC"
            },
            nextToken: nextToken
        }));
        inspectorFindings.push(...r.findings)
        nextToken = r.nextToken
    } while (nextToken !== undefined)


    //add field CVE and package
    const imageTags = new Set()
    for (const finding of inspectorFindings) {
        finding.packageVulnerabilityDetails.firstVulnerablePackage = finding.packageVulnerabilityDetails?.vulnerablePackages[0]
        for (const resource of finding.resources) {
            if (resource.details?.awsEcrContainerImage?.imageHash === page.parameters.digest) {
                for (const tag of resource.details?.awsEcrContainerImage?.imageTags || []) {
                    imageTags.add(tag)
                }
            }
        }
    }

    //const imageManifest = JSON.parse((await imagePromise).images[0].imageManifest)
    const ecrFindings = await findingsPromise

    if (ecrFindings.imageScanFindings.imageScanCompletedAt !== undefined) {
        ecrFindings.imageScanAgeDays = Math.ceil((new Date() - ecrFindings.imageScanFindings.imageScanCompletedAt) / (1000 * 60 * 60 * 24))
    }

    const inspectorFindingAggregation = (await inspectorFindingAggregationPromise).responses[0].awsEcrContainerAggregation

    root_template.append('inspector_ecr_image', {
        accountId: page.parameters.accountId,
        accountName: accounts[page.parameters.accountId].accountName,
        region: region,
        imageDigest: page.parameters.digest,
        imageTags: Array.from(imageTags),
        findings: ecrFindings,
        findingsAggregation: inspectorFindingAggregation,
        inspectorFindings: inspectorFindings,
        //imageManifest: imageManifest
    })
}