import {getPreferredCredentials, listAccountsFiltered,} from "./authentication";
import {load_template} from "./templating";
import {BatchGetImageCommand, DescribeImageScanFindingsCommand, ECRClient} from "@aws-sdk/client-ecr";
import {DescribeTaskDefinitionCommand, ECSClient, ListTaskDefinitionsCommand} from "@aws-sdk/client-ecs";
import {Inspector2Client, ListFindingAggregationsCommand, ListFindingsCommand} from "@aws-sdk/client-inspector2";
import {BatchClient, paginateDescribeJobDefinitions} from "@aws-sdk/client-batch";

// Regex pattern for ECR URIs
const ecrPattern = /^(?<account_id>\d+)\.dkr\.ecr\.(?<imageRegion>[\w-]+)\.amazonaws\.com\/(?<repo>[^:]+)(?::(?<tag>[^:]+))?$/;

export async function show_inspector_view(page) {

    if (page.path === "/inspector/") {
        await show_inspector(page)
    } else if (page.path === "/inspector/ecr/image/") {
        await show_image(page)
    } else {
        throw Error("not found")
    }

}

async function fetchActiveTaskDefinitionImageTags(ecsClient) {
    let nextToken = undefined
    let lastTaskDefinitionName = null
    let taskDefinitionCounter = 0
    const images = new Map()
    const skippedTaskDefinitionArns = new Set()
    do {
        const responseTaskDefinitions = await ecsClient.send(new ListTaskDefinitionsCommand({
            nextToken: nextToken,
            sort: "DESC"
        }))
        for (const tdArn of responseTaskDefinitions.taskDefinitionArns) {
            const result = /(arn:aws:ecs:[\w\-]+:\d+:task-definition\/[\w\-]+):(\d+)/.exec(tdArn)
            const arn = result[1]
            //we only want to load the 10 latest revisions
            if (arn === lastTaskDefinitionName) {
                taskDefinitionCounter++
            } else {
                lastTaskDefinitionName = arn
                taskDefinitionCounter = 0
            }

            if (taskDefinitionCounter <= 10) {
                const response = await ecsClient.send(new DescribeTaskDefinitionCommand({taskDefinition: tdArn}))
                for (const container of response.taskDefinition.containerDefinitions) {
                    if(!images.has(container.image)) images.set(container.image, new Set())
                    images.get(container.image).add(arn)
                }
            } else {
                skippedTaskDefinitionArns.add(arn)
            }
        }
        nextToken = responseTaskDefinitions.nextToken
    }
    while (nextToken !== undefined)
    return images
}

async function fetchActiveJobDefinitions(client) {
    const containerImages = new Map();

    const paginator = paginateDescribeJobDefinitions({client}, {status: 'ACTIVE'});

    for await (const page of paginator) {
        for (const jobDefinition of page.jobDefinitions) {
            if (jobDefinition.containerProperties) {
                const containerImage = jobDefinition.containerProperties.image;
                const jobDefinitionArn = jobDefinition.jobDefinitionArn;

                // If the image exists in the map, add the ARN to the existing set, otherwise create a new set
                if (!containerImages.has(containerImage)) {
                    containerImages.set(containerImage, new Set());
                }

                // Add the jobDefinitionArn to the Set corresponding to the container image
                containerImages.get(containerImage).add(jobDefinitionArn);
            }
        }
    }

    return containerImages;
}

async function show_inspector(page) {
    const root_template = load_template('inspector', 'content_holder')

    const accounts = await listAccountsFiltered(page.parameters.accountId)
    const regions = page.parameters.region !== undefined ? [page.parameters.region] : ["eu-west-1", "us-east-1", "eu-central-1"]

    const progressBar = document.getElementById("progress")
    progressBar.style.display = "block"
    progressBar.min = 0
    progressBar.max = Object.keys(accounts).length * regions.length
    const now = new Date()
    const promises = []
    for (const [accountId, accountDetails] of Object.entries(accounts)) {
        const await_handle = getPreferredCredentials(accountId) // fetches all accounts in parallel
            .then(async function (credentials) {
                    if (credentials) {
                        for (const region of regions) {
                            // ECR
                            const ecr_client = new ECRClient({region: region, credentials: credentials})
                            const jobDefinitionsPromise = fetchActiveJobDefinitions(new BatchClient({
                                region: region,
                                credentials: {
                                    accessKeyId: credentials.accessKeyId,
                                    secretAccessKey: credentials.secretAccessKey,
                                    sessionToken: credentials.sessionToken
                                }
                            }))
                            const taskDefinitionsPromise = fetchActiveTaskDefinitionImageTags(new ECSClient({
                                region: region,
                                credentials: credentials
                            }))
                            const allImageTags = mergeMapsOfSets(await taskDefinitionsPromise, await jobDefinitionsPromise)
                            const allImageDigests = await resolveTaggedImagesToDigests(ecr_client, region, allImageTags, page.parameters.repository)

                            console.log(`found ${allImageDigests.size} ACTIVE container image digests for account ${accountId} and region ${region}.`)

                            const inspectorClient = new Inspector2Client({region: region, credentials: credentials})
                            for (const [repo, digests] of Object.entries(allImageDigests[region])) {
                                for (const chunkedDigests of chunk([...digests.keys()], 10)) {
                                    const response = await inspectorClient.send(new ListFindingAggregationsCommand({
                                        aggregationType: 'AWS_ECR_CONTAINER',
                                        aggregationRequest: {
                                            awsEcrContainerAggregation: {
                                                repositories: [
                                                    {
                                                        comparison: 'EQUALS',
                                                        value: repo
                                                    }
                                                ],
                                                imageShas: chunkedDigests.map(digest => ({
                                                    comparison: 'EQUALS',
                                                    value: digest
                                                }))
                                            }
                                        }
                                    }))

                                    for (const r of response.responses) {
                                        const aggregation = r.awsEcrContainerAggregation;

                                        root_template.append('ecr_image_vulnerabilities', {
                                            accountId: aggregation.accountId,
                                            accountName: accounts[aggregation.accountId].accountName,
                                            region: region,
                                            repository: aggregation.repository,
                                            image: {
                                                digest: aggregation.imageSha,
                                                tags: aggregation.imageTags,
                                                severityCounts: aggregation.severityCounts
                                            },
                                            taskDefinitions: [...digests.get(aggregation.imageSha)]
                                        })
                                    }
                                }
                            }
                            progressBar.value += 1
                        }
                    }
                }
            ).catch((e) => {
                console.warn("Unable to fetch repositories for account " + accountId + ": ", e)
            })
        promises.push(await_handle)
    }
    await Promise.all(promises)
    progressBar.style.display = "none"
}

function severityOrder(severity) {
    switch (severity) {
        case "CRITICAL":
            return 0
        case "HIGH":
            return 1
        case "MEDIUM":
            return 2
        case "LOW":
            return 3
        case "INFORMATIONAL":
            return 4
        case "UNTRIAGED":
            return 5
        default:
            return 9
    }
}

async function show_image(page) {
    const root_template = load_template('inspector_image', 'content_holder')
    const accounts = await listAccountsFiltered(page.parameters.accountId)
    const region = page.parameters.region
    const credentials = await getPreferredCredentials(page.parameters.accountId)
    const client = new ECRClient({region: region, credentials: credentials})
    // const imagePromise = client.send(new BatchGetImageCommand({
    //     repositoryName: page.parameters.repository,
    //     imageIds: [{imageDigest: page.parameters.digest}]
    // }))
    const findingsPromise = client.send(new DescribeImageScanFindingsCommand({
        repositoryName: page.parameters.repository,
        imageId: {
            imageDigest: page.parameters.digest
        }
    }))

    // Initialize the Inspector2 client
    const clientInspector = new Inspector2Client({region: region, credentials: credentials}); // Replace with your region

    const inspectorFindingAggregationPromise = clientInspector.send(new ListFindingAggregationsCommand({
        aggregationType: "AWS_ECR_CONTAINER",
        aggregationRequest: {
            awsEcrContainerAggregation: {
                repositories: [{comparison: "EQUALS", value: page.parameters.repository}],
                imageShas: [{comparison: "EQUALS", value: page.parameters.digest}]
            }
        }
    }));

    let nextToken = undefined
    let inspectorFindings = []
    do {
        const r = await clientInspector.send(new ListFindingsCommand({
            filterCriteria: {
                ecrImageHash: [{comparison: "EQUALS", value: page.parameters.digest}],
                findingStatus: [
                    {comparison: "EQUALS", value: "ACTIVE"}
                ],
            },
            sortCriteria: {
                field: "SEVERITY",
                sortOrder: "DESC"
            },
            nextToken: nextToken
        }));
        inspectorFindings.push(...r.findings)
        nextToken = r.nextToken
    } while (nextToken !== undefined)


    //add field CVE and package
    const imageTags = new Set()
    for (const finding of inspectorFindings) {
        finding.packageVulnerabilityDetails.firstVulnerablePackage = finding.packageVulnerabilityDetails?.vulnerablePackages[0]
        for (const resource of finding.resources) {
            if (resource.details?.awsEcrContainerImage?.imageHash === page.parameters.digest) {
                for (const tag of resource.details?.awsEcrContainerImage?.imageTags || []) {
                    imageTags.add(tag)
                }
            }
        }
    }

    //const imageManifest = JSON.parse((await imagePromise).images[0].imageManifest)
    const ecrFindings = await findingsPromise

    if (ecrFindings.imageScanFindings.imageScanCompletedAt !== undefined) {
        ecrFindings.imageScanAgeDays = Math.ceil((new Date() - ecrFindings.imageScanFindings.imageScanCompletedAt) / (1000 * 60 * 60 * 24))
    }

    const inspectorFindingAggregation = (await inspectorFindingAggregationPromise).responses[0].awsEcrContainerAggregation

    root_template.append('inspector_ecr_image', {
        accountId: page.parameters.accountId,
        accountName: accounts[page.parameters.accountId].accountName,
        region: region,
        imageDigest: page.parameters.digest,
        imageTags: Array.from(imageTags),
        findings: ecrFindings,
        findingsAggregation: inspectorFindingAggregation,
        inspectorFindings: inspectorFindings,
        //imageManifest: imageManifest
    })
}

function mergeMapsOfSets(map1, map2) {
    // Create a new Map to hold the merged result
    const mergedMap = new Map();

    // First, copy all entries from map1 into the new mergedMap
    for (const [key, set1] of map1.entries()) {
        mergedMap.set(key, new Set(set1)); // Clone the set to ensure immutability
    }

    // Now iterate over map2 and merge with the mergedMap
    for (const [key, set2] of map2.entries()) {
        if (mergedMap.has(key)) {
            // If the key exists in mergedMap, create a new set combining both sets
            const newSet = new Set(mergedMap.get(key)); // Clone the existing set from mergedMap
            for (const value of set2) {
                newSet.add(value); // Add values from set2
            }
            mergedMap.set(key, newSet); // Set the merged set for the key
        } else {
            // If the key doesn't exist in mergedMap, simply clone the set from map2
            mergedMap.set(key, new Set(set2)); // Clone set2 to ensure immutability
        }
    }

    return mergedMap;
}

// creates am array-of-smaller-arrays from a large array
function chunk(array, size) {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
    }
    return result;
}

async function resolveTaggedImagesToDigests(ecrClient, region, taggedImages, repositoryFilter) {
    const reposAndTags = {};

    // Populate reposAndTags with tags for each region and repo
    for (const [t, source] of taggedImages.entries()) {
        const match = ecrPattern.exec(t);
        if (match) {
            const {imageRegion, repo, tag} = match.groups;
            if(repositoryFilter !== undefined && repo !== repositoryFilter) continue

            if (!reposAndTags[imageRegion]) reposAndTags[imageRegion] = {};
            if (!reposAndTags[imageRegion][repo]) reposAndTags[imageRegion][repo] = new Map();

            reposAndTags[imageRegion][repo].set(tag || 'latest', source);  // Default to 'latest' if no tag is provided
            if (imageRegion !== region) {
                console.warn(`Cross-region images not yet supported. Active region=${region}, image=${t}. Skipping`)
            }
        } else {
            console.warn(`Unable to parse container image URL '${t}' as an ECR image. Skipping. (Source: ${[...source]}`);
        }
    }

    const regionsReposAndDigests = {};
    regionsReposAndDigests[region] = {}
    for (const [repo, tags] of Object.entries(reposAndTags[region] || {})) {
        const imageIds = Array.from(tags.keys()).map(t => t.startsWith('sha256:') ? {imageDigest: t} : {imageTag: t});
        if (!regionsReposAndDigests[region][repo]) regionsReposAndDigests[region][repo] = new Map();
        try {
            // Batch requests for images in chunks of 100
            for (const chunkedIds of chunk(imageIds, 100)) {
                const res = await ecrClient.send(new BatchGetImageCommand({
                    repositoryName: repo,
                    imageIds: chunkedIds
                }))
                for (const image of res.images) {
                    const source = tags.get(image.imageId.imageDigest) ||  tags.get(image.imageId.imageTag)
                    if (image.imageManifestMediaType === 'application/vnd.oci.image.index.v1+json') {
                        const manifest = JSON.parse(image.imageManifest);
                        manifest.manifests.forEach(m => regionsReposAndDigests[region][repo].set(m.digest, source));
                    } else {
                        regionsReposAndDigests[region][repo].set(image.imageId.imageDigest, source);
                    }
                }
            }
        } catch (err) {
            if (err.name === 'RepositoryNotFoundException') {
                console.warn(`Unable to find repository ${repo} in region=${region}. Sources: ${JSON.stringify(tags.values())}`);
            } else {
                throw err;
            }
        }
    }

    return regionsReposAndDigests;
}