import {
    CloudFormationClient,
    DescribeChangeSetCommand,
    DescribeStackEventsCommand,
    DescribeStackResourcesCommand,
    DescribeStacksCommand
} from "@aws-sdk/client-cloudformation"
import {CloudWatchClient, DescribeAlarmsCommand, GetMetricStatisticsCommand} from "@aws-sdk/client-cloudwatch"
import {DescribeTargetHealthCommand, ElasticLoadBalancingV2Client} from "@aws-sdk/client-elastic-load-balancing-v2"
import {DescribeServicesCommand, DescribeTasksCommand, ECSClient, ListTasksCommand} from "@aws-sdk/client-ecs"
import {CloudTrailClient, LookupEventsCommand} from "@aws-sdk/client-cloudtrail"
import {
    get_role_credentials,
    getAccountName,
    getPreferredCredentials,
    listAccounts,
    listAccountsFiltered
} from './authentication.js'
import {printDate} from "./utils"
import {load_template} from "./templating"
import {AthenaClient, StartQueryExecutionCommand} from "@aws-sdk/client-athena";
import {CloudFrontClient, GetDistributionCommand} from "@aws-sdk/client-cloudfront";

export async function show_cloudformation_view(page) {

    if (page.path === "/cloudformation/events/") {
        await list_cloudformation_stack_events(page);
    } else if (page.path === "/cloudformation/details/") {
        await list_cloudformation_stack_details(page);
    } else if (page.path === "/cloudformation/changesets/") {
        await show_cloudformation_changesets(page);
    } else {
        await list_cloudformation_stacks(page);
    }

}

async function show_cloudformation_changesets(page) {
    const rootTemplate = load_template('cloudformation_changesets_list', 'content_holder')
    const accounts = await listAccountsFiltered()
    for(const arn of page.parameters.arns.split(',')) {
        const parts = arn.split(':')
        const accountId = parts[4]
        const region = parts[3]
        const credentials = await getPreferredCredentials(accountId)
        const client = new CloudFormationClient({region: region, credentials: credentials});
        const responseCS = await client.send(new DescribeChangeSetCommand({ChangeSetName: arn}));
        const stackId = responseCS['StackId']
        const response = await client.send(new DescribeStacksCommand({StackName: stackId}));
        if(response.Stacks.length === 1) {
            rootTemplate.append('cloudformation_changesets_list_row', {
                accountId: accountId,
                accountName: accounts[accountId].accountName,
                region: region,
                stack: response.Stacks[0],
                changeset: responseCS
            })
        }
    }
}

async function list_cloudformation_stacks(page) {
    const rootTemplate = load_template('cloudformation_list', 'content_holder')

    let accountIds
    if (page.accountId !== undefined) {
        accountIds = [page.accountId]
    } else {
        accountIds = await listAccounts()
    }

    const region = 'eu-west-1'
    const stacks = []
    if (page.parameters.changesets !== undefined) { // filter by changeset arns
        for (const cs of page.parameters.changesets.split(',')) {
            const parts = cs.split(':')
            const credentials = await get_role_credentials(parts[4])
            const client = new CloudFormationClient({region: parts[3], credentials: credentials});
            const responseCS = await client.send(new DescribeChangeSetCommand({ChangeSetName: cs}));
            const stackId = responseCS['StackId']
            const response = await client.send(new DescribeStacksCommand({StackName: stackId}));
            response.Stacks.forEach(s => {
                s['accountId'] = parts[4];
                s['region'] = parts[3]
            })
            stacks.push(...response.Stacks)
        }
    } else {
        for (const accountId of accountIds) {
            try {
                await get_role_credentials(accountId).then(async function(credentials) {
                    const client = new CloudFormationClient({region: region, credentials: credentials});
                    let nextToken = undefined
                    do {
                        const command = new DescribeStacksCommand({NextToken: nextToken});
                        const response = await client.send(command);
                        response.Stacks.forEach(s => {
                            s['accountId'] = accountId;
                            s['region'] = region
                        })
                        stacks.push(...response.Stacks)
                        nextToken = response.NextToken;
                    } while (nextToken !== undefined)
                })
            } catch (e) {
                console.log("Skipping account " + accountId + " because no role was found")
            }
        }
    }

    // Fill table
    for (const stack of stacks) {
        if (stack.RootId === undefined) { // skip nested stacks
            stack.accountName = await getAccountName(stack.accountId)
            rootTemplate.append("cloudformation_list_table_row", stack)
        }
    }
}

async function list_cloudformation_stack_details(page) {
    const rootTemplate = load_template('cloudformation_details', 'content_holder')
    const credentials = await get_role_credentials(page.parameters.accountId);
    const client = new CloudFormationClient({region: page.parameters.region, credentials: credentials});
    const cloudTrailClient = new CloudTrailClient({region: page.parameters.region, credentials: credentials});

    const resourcesPromise = get_stack_resources_recursively(client, {StackName: page.parameters.stackName}, "");

    const stacksDetails = await client.send(new DescribeStacksCommand({StackName: page.parameters.stackName}));
    const stackDetails = stacksDetails.Stacks[0]
    document.getElementById("box_cloudformation_details_stack_status").innerHTML = stackDetails.StackStatus;
    document.getElementById("box_cloudformation_details_stack_name").innerHTML = stackDetails.StackName;
    document.getElementById("box_cloudformation_details_stack_description").innerHTML = stackDetails.Description;
    document.getElementById("box_cloudformation_details_stack_id").innerHTML = stackDetails.StackId;
    document.getElementById("box_cloudformation_account").innerText = await getAccountName(page.parameters.accountId)
    document.getElementById("box_cloudformation_region").innerText = page.parameters.region
    const resources = await resourcesPromise;

    const promises = [] // all promises we wait for until the page is complete
    promises.push(list_cloudformation_stack_details_errors(client, cloudTrailClient, stackDetails, rootTemplate))

    for (const resource of resources) {
        rootTemplate.insert('cloudformation_details_resources_row', undefined, resource)
        resource.linkToCell = document.getElementById(`cloudformation_details_${resource.LogicalResourceId}_info_cell`)

        switch (resource.ResourceType) {
            case "AWS::Logs::LogGroup":
                resource.linkToCell.innerHTML = `<a href="#/cloudwatch/logs/?accountId=${page.parameters.accountId}&region=${page.parameters.region}&logGroup=${encodeURIComponent(resource.PhysicalResourceId)}">CloudWatch&nbsp;Logs</a>`
                break;
            case "AWS::Route53::HealthCheck":
                const cwClientRoute53 = new CloudWatchClient({region: 'us-east-1', credentials: credentials}); // route53 health-checks region
                promises.push(cwClientRoute53.send(new GetMetricStatisticsCommand({
                    Namespace: "AWS/Route53",
                    MetricName: "HealthCheckStatus",
                    Dimensions: [{
                        Name: "HealthCheckId",
                        Value: resource.PhysicalResourceId
                    }],
                    Period: 60,
                    StartTime: new Date(Date.now() - (1000 * 60 * 5)), // 5 minutes ago
                    EndTime: new Date(), // now
                    Statistics: ["Minimum"]
                })).then(response => {
                    let status = "UNKNOWN"
                    if (response.Datapoints !== undefined && response.Datapoints.length > 0) {
                        const lastStatus = response.Datapoints.sort((dp1, dp2) => dp1 - dp2)[0]
                        status = lastStatus.Minimum === 1 ? "HEALTHY" : "UNHEALTHY"
                    }
                    resource.linkToCell.innerText = status
                    resource.linkToCell.setAttribute('data-color', status)
                }))
                break;

            case "AWS::ElasticLoadBalancingV2::LoadBalancer":
                resource.linkToCell.innerHTML = `<a href="#/athena/redirect?accountId=${page.parameters.accountId}&region=${page.parameters.region}&stack_name=${stackDetails.StackName}&resource=${resource.PhysicalResourceId}">Athena&nbsp;Logs</a>`
                break;

            case "AWS::CloudFront::Distribution":
                resource.linkToCell.innerHTML = `<a href="#/athena/redirect?accountId=${page.parameters.accountId}&region=${page.parameters.region}&resource=${resource.PhysicalResourceId}">Athena&nbsp;Logs</a>`
                break;

            case "AWS::Lambda::Function":
                resource.linkToCell.innerHTML = `<a href="#/cloudwatch/logs/?accountId=${page.parameters.accountId}&region=${page.parameters.region}&logGroup=%2Faws%2Flambda%2F${resource.PhysicalResourceId}">CloudWatch&nbsp;Logs</a>`
                break;

        }


    }

    // enrich table with additional info from other AWS services

    const cloudFormationAlarms = resources.filter(resource => resource.ResourceType === "AWS::CloudWatch::Alarm");
    if (cloudFormationAlarms.length > 0) {
        const cwClient = new CloudWatchClient({region: page.parameters.region, credentials: credentials});
        // simplification: We expect all alarms to start with the stack name
        const cloudWatchResponse = await cwClient.send(new DescribeAlarmsCommand({AlarmNamePrefix: page.parameters.stackName}));

        for (const alarmResource of cloudFormationAlarms) {
            const cloudWatchAlarm = cloudWatchResponse.MetricAlarms.find(a => a.AlarmName === alarmResource.PhysicalResourceId);
            if (cloudWatchAlarm !== undefined) {
                alarmResource.linkToCell.innerHTML = cloudWatchAlarm.StateValue
                alarmResource.linkToCell.setAttribute('data-color', cloudWatchAlarm.StateValue)
            }
        }
    }

    const cloudFormationElbs = resources.filter(resource => resource.ResourceType === "AWS::ElasticLoadBalancingV2::TargetGroup");
    const elbClient = new ElasticLoadBalancingV2Client({region: page.parameters.region, credentials: credentials});
    for (const cloudFormationElb of cloudFormationElbs) {
        promises.push(elbClient.send(new DescribeTargetHealthCommand({TargetGroupArn: cloudFormationElb.PhysicalResourceId})).then(elbResponse => {
            const totalCount = elbResponse.TargetHealthDescriptions.length;
            const healthyCount = elbResponse.TargetHealthDescriptions.filter(d => d.TargetHealth.State === "healthy").length
            cloudFormationElb.linkToCell.innerHTML = `${healthyCount} / ${totalCount} healthy`
            if (totalCount > 0) {
                const rowBefore = cloudFormationElb.linkToCell.parentNode;
                const elbTemplate = rootTemplate.insert('cloudformation_details_resources_row_elb', rowBefore);
                for (const target of elbResponse.TargetHealthDescriptions) {
                    elbTemplate.append('cloudformation_details_resources_row_elb_row', target)
                }
            }

        }).catch(console.info));
    }

    const cloudFormationContainerServices = resources.filter(resource => resource.ResourceType === "AWS::ECS::Service");
    const ecsClient = new ECSClient({region: page.parameters.region, credentials: credentials});
    for (const cloudFormationContainerService of cloudFormationContainerServices) {
        const [_, clusterName, serviceName] = cloudFormationContainerService.PhysicalResourceId.split('/');
        promises.push(ecsClient.send(new DescribeServicesCommand({
            cluster: clusterName,
            services: [serviceName]
        })).then(async (ecsResponse) => {
            if (ecsResponse.services.length === 1) {
                const ecsService = ecsResponse.services[0];
                cloudFormationContainerService.linkToCell.innerHTML = `${ecsService.status} (${ecsService.runningCount} / ${ecsService.desiredCount} tasks running)`;

                const ecsResponseRunningTasks = await ecsClient.send(new ListTasksCommand({
                    cluster: clusterName,
                    serviceName: serviceName
                }));
                const ecsResponseStoppedTasks = await ecsClient.send(new ListTasksCommand({
                    cluster: clusterName,
                    serviceName: serviceName,
                    desiredStatus: 'STOPPED',
                    maxResults: 11
                }));
                const tasks =
                    [...ecsResponseRunningTasks.taskArns, ...ecsResponseStoppedTasks.taskArns.slice(0, 10)]
                        .slice(0, 100);
                if (tasks.length > 0) {
                    const ecsResponseTasks = await ecsClient.send(new DescribeTasksCommand({
                        cluster: clusterName,
                        tasks: tasks
                    }));

                    const rowBefore = cloudFormationContainerService.linkToCell.parentNode;
                    const ecsTemplate = rootTemplate.insert('cloudformation_details_resources_row_ecs', rowBefore);
                    for (const task of ecsResponseTasks.tasks) {
                        ecsTemplate.append('cloudformation_details_resources_row_ecs_task_row', {
                            'taskId': task.taskArn.match(/[a-f0-9]+$/g),
                            'status': task.lastStatus,
                            'health': task.healthStatus,
                            'code': task.stopCode || "",
                            'reason': task.stoppedReason || ""
                        })
                    }
                    if (ecsResponseStoppedTasks.taskArns.length === 11) {
                        ecsTemplate.append('cloudformation_details_resources_row_ecs_task_row_info', {
                            'info_message': "Showing only the 10 most recent stopped tasks"
                        })
                    }
                }
            }
        }))
    }

    // show logs and alarms
    // const logsAndMetricsElement = rootTemplate.insert('cloudformation_details_logs_alarms')

    // wait for complete rendering to complete
    await Promise.all(promises)
}

const stackStatusTerminal = ["UPDATE_COMPLETE", "UPDATE_FAILED", "UPDATE_ROLLBACK_COMPLETE", "UPDATE_ROLLBACK_FAILED", "CREATE_COMPLETE", "CREATE_FAILED", "DELETE_FAILED", "ROLLBACK_COMPLETE"]
const stackStatusStart = ["UPDATE_IN_PROGRESS", "CREATE_IN_PROGRESS", "DELETE_IN_PROGRESS"]

/**
 * Lists all stack events for a (root) stack and their nested stacks, recursively.
 *
 * If deploymentStartTime is not given, then the first found ..._PENDING event on the stack
 * will be used. Events from nested stacks will be limited to the deployment start/end time
 *
 * @param client
 * @param stackName
 * @param deploymentStartTime
 * @param deploymentEndTime
 * @returns {Promise<void>}
 */
async function list_cloudformation_stack_events_recursively(client, stackName, deploymentStartTime = undefined, deploymentEndTime = undefined) {
    console.info("Loading cloudformation stack events for " + stackName)
    const results = []
    const nestedStacks = new Set()
    let nextToken = undefined
    do {
        const response = await client.send(new DescribeStackEventsCommand({
            StackName: stackName,
            NextToken: nextToken
        }))
        // first iteration and unknown end time, check what the first event is
        if (deploymentEndTime === undefined && nextToken === undefined) {
            if (response.StackEvents[0].LogicalResourceId === stackName) {
                if (stackStatusTerminal.includes(response.StackEvents[0].ResourceStatus)) {
                    deploymentEndTime = response.StackEvents[0].Timestamp
                }
            }
        }
        nextToken = response.NextToken
        for (const event of response.StackEvents) {
            // if we know the deployment start time, check it.
            if (event.Timestamp < deploymentStartTime) {
                break
            }

            results.push(event)

            // collect all nested stacks
            if (event.ResourceType === "AWS::CloudFormation::Stack" && event.LogicalResourceId !== stackName && event.PhysicalResourceId !== stackName) {
                if (event.PhysicalResourceId !== "") {
                    nestedStacks.add(event.PhysicalResourceId)
                }
            }

            // stop here if we found the deployment start event
            if (event.LogicalResourceId === stackName) {
                if (stackStatusStart.includes(event.ResourceStatus)) {
                    deploymentStartTime = event.Timestamp
                    nextToken = undefined // end loop
                    break
                }
            }
        }
    } while (nextToken !== undefined)

    const promises = [...nestedStacks].map(physicalResourceId =>
        list_cloudformation_stack_events_recursively(client, physicalResourceId, deploymentStartTime, deploymentEndTime
        ))

    promises.push(Promise.resolve({events: results}))
    return Promise.all(promises).then(obj => {
        return {
            events: obj.map(o => o.events).flat(),
            deploymentStartTime: deploymentStartTime,
            deploymentEndTime: deploymentEndTime
        }
    })
}

async function list_cloudformation_stack_details_errors(client, cloudtrailClient, stackDetails, htmlTemplate) {

    const results = await list_cloudformation_stack_events_recursively(client, stackDetails.StackName)
    const deploymentStartTime = results.deploymentStartTime
    const deploymentEndTime = results.deploymentEndTime
    const allErrorEvents = results.events.filter(event => event.ResourceStatus.endsWith("_FAILED"))

    htmlTemplate.insert('cloudformation_details_error_events_details', undefined, {
        start: printDate(deploymentStartTime),
        end: printDate(deploymentEndTime) || "pending"
    })

    allErrorEvents.sort((a, b) => a.Timestamp - b.Timestamp)

    for (const event of allErrorEvents) {
        htmlTemplate.append('cloudformation_details_error_events_row', event)
    }

    const daysSinceDeploymentStart = Math.floor((new Date() - deploymentStartTime) / (1000 * 60 * 60 * 24))
    if (daysSinceDeploymentStart >= 90) {
        document.getElementById("list_cloudformation_cloudtrail_info").innerHTML = "CloudTrail event are only available for 90 days";
    } else {
        const cloudTrailStartDate = new Date(deploymentStartTime) // cloudtrail events happen before stack events
        cloudTrailStartDate.setMinutes(cloudTrailStartDate.getMinutes() - 5)

        const cloudtrailEvents = await cloudtrailClient.send(new LookupEventsCommand({
            LookupAttributes: [
                {AttributeKey: "ResourceName", AttributeValue: stackDetails.StackName}
            ],
            StartTime: cloudTrailStartDate,
            EndTime: deploymentEndTime
        }));
        const filtered = cloudtrailEvents.Events.filter(e => e.EventName === "ExecuteChangeSet"); // we need the stack create/update event
        if (filtered.length > 0) {
            const event = filtered[0];

            const table = document.getElementById("list_cloudformation_cloudtrail_errors")
            const row = table.insertRow();
            row.insertCell(0).innerHTML = printDate(event.EventTime);
            row.insertCell(1).innerHTML = event.EventSource;
            row.insertCell(2).innerHTML = event.EventName;
            row.insertCell(3).innerHTML = '';
            row.insertCell(4).innerHTML = '';
            document.getElementById("list_cloudformation_cloudtrail_info").innerHTML = `Principal: ${event.Username} (${event.AccessKeyId})`;

            let nextToken = undefined
            do {
                const cloudtrailDeploymentEvents = await cloudtrailClient.send(new LookupEventsCommand({
                    LookupAttributes: [
                        {AttributeKey: "Username", AttributeValue: event.Username}
                    ],
                    StartTime: cloudTrailStartDate,
                    EndTime: deploymentEndTime,
                    NextToken: nextToken
                }));
                for (const e of cloudtrailDeploymentEvents.Events) {
                    const json = JSON.parse(e.CloudTrailEvent);
                    if (json.sourceIPAddress === "cloudformation.amazonaws.com") { // only events triggered by cloudformation
                        if (json.errorCode !== undefined) {
                            const row = table.insertRow();
                            row.insertCell(0).innerHTML = printDate(e.EventTime);
                            row.insertCell(1).innerHTML = json.eventSource;
                            row.insertCell(2).innerHTML = json.eventName;
                            row.insertCell(3).innerHTML = json.errorCode || "";
                            row.insertCell(4).innerHTML = json.errorMessage || "";
                        }
                    }
                }
                nextToken = cloudtrailDeploymentEvents.NextToken;
            } while (nextToken !== undefined)
        } else {
            document.getElementById("list_cloudformation_cloudtrail_info").innerHTML = "No stack events found, yet."
        }
    }
}

async function get_stack_resources_recursively(client, describeStackResourceCommandInput, parentStacks) {
    let input = {...describeStackResourceCommandInput};
    let output = [];
    input.nextToken = undefined;
    do {
        const response = await client.send(new DescribeStackResourcesCommand(input));
        for (const detail of response.StackResources) {
            // for better readability we prefix the logical names in nested stacks
            detail.LogicalResourceId = parentStacks + detail.LogicalResourceId;
            output.push(detail);
            if (detail.ResourceType === 'AWS::CloudFormation::Stack') {
                const results = await get_stack_resources_recursively(client, {StackName: detail.PhysicalResourceId}, parentStacks + detail.LogicalResourceId + "/")
                output.push(...results);
            }
        }
        input.nextToken = response.NextToken;
    } while (input.nextToken !== undefined && output.length < 10000)
    return output;
}

async function list_cloudformation_stack_events(page) {
    const client = new CloudFormationClient({
        region: page.parameters.region,
        credentials: get_role_credentials(page.parameters.accountId)
    });

    let nextToken = undefined
    do {
        const command = new DescribeStackEventsCommand({NextToken: nextToken, StackName: page.parameters.stackName});
        const response = await client.send(command);
        //const table = document.getElementById("box_cloudformation_events_body");

        console.log(response)

        nextToken = response.NextToken;
    } while (nextToken !== undefined)
}

window.openAthenaQuery = async function (event, accountId, region, physicalResourceId) {
    event.preventDefault();
    get_role_credentials(accountId).then(async credentials => {
        const client = new AthenaClient({credentials: credentials, region: region})
        let query
        if (physicalResourceId.startsWith("arn:aws:elasticloadbalancing:")) { // ELBv2
            const today = new Date().toISOString().substring(0, 10).replaceAll("-", "/")
            const elbName = physicalResourceId.substring(`arn:aws:elasticloadbalancing:${region}:${accountId}:loadbalancer/`.length)
            query = `SELECT time, request_verb, request_url, elb_status_code, user_agent, *
                     FROM aws_infrastructure.elbv2
                     WHERE day = '${today}' AND elb = '${elbName}' LIMIT 200`
        } else if (/^\w+$/.test(physicalResourceId)) { // CloudFront distribution
            const cloudFrontClient = new CloudFrontClient({credentials: credentials, region: region})
            const cloudFrontDistribution = await cloudFrontClient.send(new GetDistributionCommand({Id: physicalResourceId}))
            const host = cloudFrontDistribution.Distribution.DomainName
            query = `-- WARNING this table is NOT PARTITIONED. Queries take long and are expensive\nSELECT * FROM aws_infrastructure.cloudfront WHERE host = '${host}' LIMIT 200;`
        }
        client.send(new StartQueryExecutionCommand({
            QueryString: query,
            WorkGroup: 'primary'
        })).then(response => {
            console.log("Started initial Athena query:", response)
            const url = encodeURIComponent(`https://eu-west-1.console.aws.amazon.com/athena/home?region=${region}#/query-editor/history/${response.QueryExecutionId}`)
            window.open(`#openUrl?accountId=${accountId}&url=${url}`)
        })
    })
}